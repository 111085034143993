import React, { Component } from 'react';
import $ from 'jquery';

import { ajax_wrapper, update_history, get_global_state } from 'functions';
import { NewProject, ProjectCard } from 'components';
import { Container, Loading, Button, Modal, Select } from 'library';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.song-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class Projects extends Component {
    constructor(props) {
        super(props);

        let params = get_global_state()['params'];

        this.state = {
            loaded: false,
            projects: [],
            admin_projects: [],
            selected_client: params['client'] ? params['client'] : '',
            show_project_create: false,
            tagger: { name: '' },
        };
    }

    componentDidMount() {
        this.refresh_projects();

        window.addEventListener('popstate', this.handle_history_change);
    }

    refresh_projects = () => {
        ajax_wrapper('GET', `/api/my_projects/`, {}, (value) =>
            this.setState({
                loaded: true,
                projects: value['projects'],
                admin_projects: value['admin_projects'],
                tagger: value['tagger'],
                admin: value['admin'],
            }),
        );
    };

    handle_history_change = (event) => {
        let params = get_global_state()['params'];

        if (params['client'] != this.state.selected_client) {
            this.setState({
                selected_client: params['client'],
            });
        }
    };

    render() {
        let projects = [];
        let tagger_name = this.state.tagger['name'];
        let user = get_global_state()['user'];

        if (['owner', 'admin'].includes(user['role'])) {
            if (
                this.state.projects.length + this.state.admin_projects.length >
                0
            ) {
                let new_project_button = (
                    <div
                        className="simple-card project-card flex-container"
                        style={{ width: '80px' }}
                    >
                        <Button
                            type="primary"
                            onClick={() =>
                                this.setState({ show_project_create: true })
                            }
                            className="dark-button new-project-button"
                        >
                            +
                        </Button>
                    </div>
                );
                projects.push(new_project_button);
            }
        }

        if (user['role'] != 'admin') {
            for (let item of this.state.projects) {
                projects.push(
                    <ProjectCard
                        data={item}
                        tagger={this.state.tagger}
                        admin={false}
                    />,
                );
            }
        } else {
            for (let item of this.state.admin_projects) {
                if (
                    item.client == this.state.selected_client ||
                    !this.state.selected_client
                ) {
                    projects.push(
                        <ProjectCard
                            data={item}
                            tagger={this.state.tagger}
                            admin={true}
                        />,
                    );
                }
            }
        }

        // TODO: change below card to not show for non-admins

        if (projects.length === 0) {
            let owner_options = null;
            if (['owner', 'admin'].includes(user['role'])) {
                owner_options = (
                    <div>
                        <i>{'—> Select from the following —> '}</i>
                        <br />
                        <br />
                        <Button
                            type="primary"
                            className="dark-button"
                            onClick={() =>
                                this.setState({ show_project_create: true })
                            }
                        >
                            Create a new project
                        </Button>
                        <br />
                        <Button
                            type="primary"
                            className="dark-button"
                            href="#admin-projects"
                        >
                            View active projects
                        </Button>
                        <br />
                    </div>
                );
            }
            projects.push(
                <div style={{ paddingBottom: '10px' }}>
                    <h3>You have no active projects!</h3>
                    {owner_options}
                </div>,
            );
        }

        let projects_jsx = [];

        if (user['role'] != 'admin') {
            projects_jsx.push(
                <div id="tagger-projects">
                    <h1>Active Projects - {tagger_name}</h1>
                    <div className="simple-card-container row">{projects}</div>
                    <br />
                </div>,
            );
        } else {
            projects_jsx.push(
                <div id="admin-projects">
                    <div
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'center',
                        }}
                    >
                        <h1
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'center',
                            }}
                        >
                            All Projects (Admin)
                        </h1>
                        <Select
                            placeholder="Filter by Client"
                            name="selected_client"
                            className="transparent-select"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                            }}
                            options_url={'/api/home/client/'}
                            value={this.state.selected_client}
                            set_form_state={function (state) {
                                this.setState(state);
                                update_history(
                                    {},
                                    `/?client=${state['selected_client']}`,
                                );
                            }.bind(this)}
                        />
                    </div>

                    <div className="simple-card-container row">{projects}</div>
                </div>,
            );
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div>
                        <Modal
                            show={this.state.show_project_create}
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_project_create: false,
                                    },
                                    focus_on_page,
                                )
                            }
                        >
                            <div className="simple-card-container modal-bounding-box">
                                <div className="simple-card">
                                    <NewProject />
                                </div>
                            </div>
                        </Modal>

                        <div>{projects_jsx}</div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
