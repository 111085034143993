import React, { Component } from 'react';
import { export_taxonomy_template } from 'functions';
import { Form, Container, FileInput, Select } from 'library';

export class UploadPresets extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container>
                <h3>Upload Presets</h3>
                <Form
                    defaults={this.state.data}
                    submit_text="Upload"
                    submit_url={`/api/import_presets/${this.props['taxonomy_id']}/`}
                    redirect={() => {
                        window.location.href = `/admin/taxonomy/${this.props['taxonomy_id']}/`;
                    }}
                >
                    <FileInput name="file" label="Excel File" required={true} />
                    <Select
                        name="overwrite"
                        label="Overwrite Existing?"
                        required={true}
                        options={[
                            {
                                text: 'True (deletes all existing presets)',
                                value: true,
                            },
                            { text: 'False', value: false },
                        ]}
                    />
                    <br />
                </Form>
            </Container>
        );
    }
}

export class AddTagTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container>
                <h3>Upload New TagTypes:</h3>
                <a
                    onClick={() => export_taxonomy_template()}
                    style={{ fontStyle: 'italic', textDecoration: 'underline' }}
                >
                    Download Template Here
                </a>
                <Form
                    defaults={this.state.data}
                    submit_text="Upload"
                    submit_url={`/api/add_tagtypes/${this.props['taxonomy_id']}/`}
                    redirect={() => {
                        window.location.href = `/admin/taxonomy/${this.props['taxonomy_id']}/`;
                    }}
                >
                    <FileInput name="file" label="Excel File" required={true} />
                    <br />
                </Form>
            </Container>
        );
    }
}
