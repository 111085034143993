class UserValidator {
    logged_id() {
        if (!window.secret_react_state.user) {
            return false;
        }

        return true;
    }

    is_staff() {
        if (!this.logged_id()) {
            return false;
        }

        let user = window.secret_react_state.user;

        let valid_staff = false;
        if (user.is_staff) {
            valid_staff = true;
        }

        return valid_staff;
    }

    get_role() {
        if (!this.logged_id()) {
            return 'anon';
        }
        let user = window.secret_react_state.user;

        return user['role'];
    }

    dropbox_creds() {
        let user = window.secret_react_state.user;
        return user.dropbox_token;
    }
}

export default UserValidator;
