import React, { Component } from 'react';

import {
    Form,
    TextInput,
    Container,
    Loading,
    Select,
    MultiSelect,
} from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class ManageClient extends Component {
    constructor(props) {
        super(props);

        let params = get_global_state()['params'];
        let url = params['id']
            ? `/api/home/client/${params['id']}/`
            : `/api/home/client/`;

        this.state = {
            url: url,
            loaded: false,
            data: {},
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];
        let user = get_global_state()['user'];

        if (params['id']) {
            ajax_wrapper(
                'GET',
                this.state.url,
                {},
                function (value) {
                    if (!('owner' in value)) {
                        value['owner'] = user.id;
                    }
                    this.setState({ data: value }, () =>
                        ajax_wrapper(
                            'GET',
                            `/api/home/taxonomy/?clients=${params['id']}`,
                            {},
                            function (value) {
                                let data = this.state.data;
                                data['taxonomies'] = [];
                                for (let item of value) {
                                    data['taxonomies'].push(item['id']);
                                }
                                this.setState({ loaded: true, data: data });
                            }.bind(this),
                        ),
                    );
                }.bind(this),
            );
        } else {
            this.setState({ loaded: true, data: { owner: user.id } });
        }
    }

    render() {
        // Limit defaults to editable values only
        let defaults = {
            name: this.state.data['name'],
            owner: this.state.data['owner'],
            taxonomies: this.state.data['taxonomies'],
        };

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div className="simple-card-container">
                        <div className="simple-card">
                            <Form
                                defaults={defaults}
                                submit_url={this.state.url}
                                redirect={function () {
                                    window.location.href =
                                        '/admin/home/client/';
                                }}
                            >
                                <TextInput name="name" label="Name" />
                                <Select
                                    name="owner"
                                    label="Owner"
                                    options_url="/api/user/users/"
                                />
                                <MultiSelect
                                    name="taxonomies"
                                    label="Taxonomies"
                                    options_url="/api/home/taxonomy/"
                                />
                                <br />
                            </Form>
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
