import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Container, Loading, Button } from 'library';
import { SyncStatus } from 'components';

export default class DropboxSync extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            sync: null,
            songs: [],
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/api/home/dropboxsync/${params['id']}/`,
            {},
            (value) => this.setState({ loaded: true, sync: value }),
        );

        ajax_wrapper(
            'GET',
            `/api/home/songsync/?sync=${params['id']}&only_fields=name,status`,
            {},
            (value) => this.setState({ songs: value }),
        );
    }

    restart_sync = () => {
        let sync = this.state.sync;

        ajax_wrapper(
            'POST',
            `/api/restart_sync/${sync['id']}/`,
            {},
            function () {
                window.location.reload();
            },
        );
    };

    render() {
        let sync = this.state.sync;

        let songs = [];
        for (let item of this.state.songs) {
            songs.push(
                <div className="simple-card">
                    <span
                        style={{ fontStyle: 'italics', float: 'right' }}
                    >{`${item['status']}`}</span>
                    <span>{`${item['name']}`}</span>
                </div>,
            );
        }

        let restart_button = null;
        if (sync && sync['status'] == 'Failed') {
            restart_button = (
                <Button
                    type="warning"
                    onClick={this.restart_sync}
                    style={{ marginRight: '10px' }}
                >
                    Restart
                </Button>
            );
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div>
                        <div>
                            <h2>Dropbox Sync</h2>
                        </div>

                        <div>
                            <div className="simple-card-container">
                                <div className="simple-card">
                                    <div style={{ float: 'right' }}>
                                        {restart_button}
                                    </div>

                                    <h4>
                                        {`Folder: ${
                                            sync ? sync['dropbox_folder'] : null
                                        }`}
                                    </h4>

                                    <div>
                                        <SyncStatus sync={sync} />
                                    </div>
                                </div>

                                {songs}
                            </div>
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
