import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

class AudioPlayer extends React.Component {
    render() {
        return (
            <ReactAudioPlayer
                className={this.props.className}
                style={this.props.style}
                src={this.props.src}
                controls
                loop={this.props.loop}
                preload={this.props.preload}
            />
        );
    }
}

export default AudioPlayer;
