import React, { Component } from 'react';

import { Button, Alert } from 'library';
import { SyncStatus } from 'components';
export default class SyncCard extends Component {
    render() {
        let sync = this.props.data;

        return (
            <div className="simple-card">
                <div style={{ float: 'right' }}>
                    <Button
                        type="primary"
                        href={`/dropbox_sync/${sync['id']}/`}
                    >
                        View
                    </Button>
                </div>
                <h4>{sync['dropbox_folder']}</h4>
                <p style={{ fontStyle: 'italic' }}>{sync['project_name']}</p>
                <div>
                    <SyncStatus sync={sync} />
                    <span
                        style={{ display: 'inline-block', padding: '10px' }}
                    >{`|`}</span>
                    <span>{`Songs: ${sync['completed_songs']} / ${sync['total_songs']}`}</span>
                </div>
            </div>
        );
    }
}
