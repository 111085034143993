const HISTORY_NAME = 'TuneTagger';

function update_history(data, uri, replace) {
    if (replace && replace === true) {
        window.history.replaceState(data, HISTORY_NAME, uri);
    } else {
        window.history.pushState(data, HISTORY_NAME, uri);
    }
}

export default update_history;
