import React, { Component } from 'react';

import { Link } from 'library';

export default class AdminCard extends Component {
    render() {
        let data = this.props.data;
        let name = data['name'];

        return (
            <Link className="simple-card admin-card" href={`/${data['url']}/`}>
                <h3>{name}</h3>

                <p style={{ fontStyle: 'italic' }}>{data['helper_text']}</p>
            </Link>
        );
    }
}
