import React, { Component } from 'react';

import { Form, TextInput, Container, Loading, Button, Modal } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

import { UploadPresets, AddTagTypes } from './import_presets';

class TagType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    show_hide = () => {
        if (this.state.show) {
            this.setState({ show: false, dropdown_button_rotation: 0 });
        } else {
            this.setState({ show: true, dropdown_button_rotation: 180 });
        }
    };

    render() {
        let dropdown_arrow = (
            <img
                class="clear-text-button"
                src="/static/images/dropdown-button-white.png"
                style={{
                    height: '8px',
                    opacity: '80%',
                    float: 'right',
                    marginTop: '13px',
                    marginLeft: '10px',
                    marginRight: '5px',
                    rotate: this.state.dropdown_button_rotation + 'deg',
                }}
            />
        );
        let tags = [];
        for (let item of this.props.data['tags']) {
            tags.push(<h4>{item['name']}</h4>);

            for (let child of item['children']) {
                tags.push(
                    <div style={{ paddingLeft: '15px' }}>{child['name']}</div>,
                );
            }
        }

        return (
            <div className="simple-card">
                <div onClick={this.show_hide}>
                    <h5 style={{ display: 'inline-block', width: '20%' }}>
                        {this.props.data['name']}
                    </h5>
                    <p
                        style={{
                            display: 'inline-block',
                            color: 'rgba(221, 47, 47, 1)',
                            fontStyle: 'italic',
                        }}
                    >
                        {this.props.data['required'] ? 'Required' : ''}
                    </p>
                    {dropdown_arrow}
                </div>
                <div style={{ display: this.state.show ? 'block' : 'none' }}>
                    <table>
                        <tr style={{ paddingBottom: '0px' }}>
                            <td
                                style={{
                                    paddingRight: '20px',
                                    height: '12px',
                                }}
                            >
                                <h6 style={{ fontWeight: 'lighter' }}>
                                    Minimum tags:
                                </h6>
                            </td>
                            <td>
                                <h6 style={{ fontWeight: 'lighter' }}>
                                    {this.props.data['min']}
                                </h6>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h6 style={{ fontWeight: 'lighter' }}>
                                    Maximum tags:
                                </h6>
                            </td>
                            <td style={{ fontWeight: 'lighter' }}>
                                {this.props.data['max']}
                            </td>
                        </tr>
                        <div>{tags}</div>
                    </table>
                </div>
            </div>
        );
    }
}

class Preset extends Component {
    render() {
        let presets_by_tagtype = {};
        let preset_tags = [];
        for (let child of this.props.data['preset_tags']) {
            if (!Object.keys(presets_by_tagtype).includes(child.tag_type)) {
                presets_by_tagtype[child.tag_type] = [];
            }
            presets_by_tagtype[child.tag_type].push(
                <div className="badge text-bg-info">{child.name}</div>,
            );
        }

        let color_increment = 50;
        for (let tag_type of Object.keys(presets_by_tagtype)) {
            preset_tags.push(
                <div>
                    <div
                        style={{
                            backgroundColor: `rgba(120, ${color_increment}, 40, .6)`,
                            width: '95%',
                            paddingLeft: '5px',
                            marginBottom: '3px',
                            borderRadius: '4px',
                        }}
                    >
                        {tag_type}
                    </div>
                    {presets_by_tagtype[tag_type]}
                </div>,
            );
            color_increment = color_increment + 40;
        }

        return (
            <div className="simple-card">
                <h4>{this.props.data['name']}</h4>
                {preset_tags}
                <br />
            </div>
        );
    }
}

export default class ManageTaxonomy extends Component {
    constructor(props) {
        super(props);
        let params = get_global_state()['params'];

        let url = `/api/home/taxonomy/${params['id']}/`;

        this.state = {
            url: url,
            loaded: false,
            data: null,
            tags: [],
            presets: [],
            upload_presets: false,
            add_tagtypes: false,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper('GET', this.state.url, {}, (value) =>
            this.setState({ loaded: true, data: value }),
        );

        if (params['id']) {
            ajax_wrapper(
                'GET',
                `/api/taxonomy_tags/${params['id']}/`,
                {},
                (value) => this.setState({ loaded: true, tags: value }),
            );
            ajax_wrapper(
                'GET',
                `/api/taxonomy_presets/${params['id']}/`,
                {},
                (value) => this.setState({ loaded: true, presets: value }),
            );
        }
    }

    render() {
        let params = get_global_state()['params'];

        let tag_types = [];
        for (let item of this.state.tags) {
            tag_types.push(<TagType data={item} />);
        }

        let presets = [];
        for (let item of this.state.presets.sort((a, b) =>
            a.name.localeCompare(b.name),
        )) {
            presets.push(<Preset data={item} />);
        }

        let upload_presets = (
            <Modal
                show={this.state.upload_presets}
                on_hide={() => this.setState({ upload_presets: false })}
            >
                <div className="simple-card">
                    <UploadPresets taxonomy_id={params['id']} />
                </div>
            </Modal>
        );

        let add_tagtypes = (
            <Modal
                show={this.state.add_tagtypes}
                on_hide={() => this.setState({ add_tagtypes: false })}
            >
                <div className="simple-card">
                    <AddTagTypes taxonomy_id={params['id']} />
                </div>
            </Modal>
        );

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <Form
                        defaults={this.state.data}
                        submit_url={this.state.url}
                        delete_url={this.state.url}
                        redirect={function () {
                            window.location.href = '/admin/home/taxonomy/';
                        }}
                    >
                        <TextInput name="name" label="name" />
                    </Form>

                    {params['id'] ? (
                        <div>
                            <Button
                                type="primary"
                                href={`/admin/exportformat/?taxonomy=${params['id']}`}
                            >
                                Add Export Format
                            </Button>
                            <Button
                                type="primary"
                                href={`/admin/uilayout/?taxonomy=${params['id']}`}
                            >
                                Add UI Layout
                            </Button>
                            <Button
                                type="primary"
                                href={`/admin/mlmodel/?taxonomy=${params['id']}`}
                            >
                                Add ML Model
                            </Button>
                            <Button
                                type="primary"
                                onClick={() =>
                                    this.setState({ upload_presets: true })
                                }
                            >
                                Upload Presets
                            </Button>
                            <Button
                                type="primary"
                                onClick={() =>
                                    this.setState({ add_tagtypes: true })
                                }
                            >
                                Add TagTypes
                            </Button>
                        </div>
                    ) : null}
                    <div>
                        <div className="simple-card-container">{tag_types}</div>
                    </div>
                    <div>
                        <h4>Presets</h4>
                        <div className="simple-card-container">{presets}</div>
                    </div>

                    {upload_presets}
                    {add_tagtypes}
                </Loading>
            </Container>
        );
    }
}
