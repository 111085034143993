import React, { Component } from 'react';

import { Image } from 'library';

class Header extends Component {
    render() {
        let items = [];

        if (this.props.role == 'admin') {
            items.push(
                <li className="nav-item">
                    <a className="nav-link" href="/admin/">
                        Admin
                    </a>
                </li>,
            );
        }
        if (this.props.role == 'owner') {
            items.push(
                <li className="nav-item">
                    <a className="nav-link" href="/manage_taggers/">
                        Manage Taggers
                    </a>
                </li>,
            );
        }

        items.push(
            <li className="nav-item">
                <a className="nav-link" href="/logout/">
                    Logout
                </a>
            </li>,
        );

        return (
            <nav
                className="navbar navbar-expand-lg"
                style={{
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, .2)',
                    marginBottom: '10px',
                }}
            >
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <Image
                            src="/static/images/tunetagger_logo_darkmode_outline.png"
                            style={{ maxHeight: '100px' }}
                        />
                    </a>
                    <ul className="navbar-nav ml-auto">{items}</ul>
                </div>
            </nav>
        );
    }
}

export default Header;
