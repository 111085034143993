import React, { Component } from 'react';

import { Alert } from 'library';

export default class SyncStatus extends Component {
    render() {
        let sync = this.props.sync;

        let type = 'warning';
        if (sync['status'] == 'Syncing') {
            type = 'info';
        } else if (sync['status'] == 'Synced') {
            type = 'success';
        } else if (sync['status'] == 'Failed') {
            type = 'danger';
        }

        return (
            <Alert
                style={{
                    display: 'inline-block',
                    margin: '0px',
                    padding: '8px 16px',
                }}
                type={type}
            >{`Status: ${sync['status']}`}</Alert>
        );
    }
}
