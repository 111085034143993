import React from 'react';

import { Modal, Button, Loading } from 'library';
import { ajax_wrapper } from 'functions';

class DeleteModalContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            waiting_for_response: false,
        };

        this.delete = this.delete.bind(this);
    }

    delete() {
        this.setState(
            {
                waiting_for_response: true,
            },
            function () {
                this.props.delete();
            }.bind(this),
        );
    }

    render() {
        let delete_type = 'danger';
        let text_style = {};

        let prompt_text = this.props.archive_only
            ? `${this.props.delete_text}?`
            : `Permanently ${this.props.delete_text.toLowerCase()}?`;
        let warning_text = this.props.archive_only
            ? ''
            : '(This is not undoable)';

        return (
            <div
                className="simple-card-container"
                style={{
                    padding: '1rem',
                    margin: '20px 15px 20px 15px',
                }}
            >
                <Loading loaded={!this.state.waiting_for_response} cover={true}>
                    <div
                        className="simple-card"
                        style={{
                            textAlign: 'center',
                            maxWidth: '50%',
                            margin: 'auto',
                        }}
                    >
                        <h5 style={text_style}>{prompt_text}</h5>
                        <h6>{warning_text}</h6>
                        <br />
                        <div className="row">
                            <div
                                className="col-lg-6"
                                style={{ textAlign: 'center' }}
                            >
                                <Button
                                    onClick={this.delete}
                                    type={delete_type}
                                    style={{ margin: 'auto' }}
                                    disabled={this.state.waiting_for_response}
                                >
                                    {this.props.delete_text}
                                </Button>
                            </div>
                            <div
                                className="col-lg-6"
                                style={{ textAlign: 'center', margin: 'auto' }}
                            >
                                <Button
                                    type="secondary"
                                    style={{ margin: 'auto' }}
                                    onClick={this.props.close}
                                >
                                    Nevermind
                                </Button>
                            </div>
                        </div>
                    </div>
                </Loading>
            </div>
        );
    }
}

export default class DeleteButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            waiting_for_response: false,
            show_confirm_modal: false,
            archive_only: this.props.archive_only
                ? this.props.archive_only
                : false,
        };
    }

    open = () => {
        this.setState({ show_confirm_modal: true });
    };

    close = () => {
        this.setState({ show_confirm_modal: false });
    };

    delete = () => {
        let url = `${this.props.url}`;
        if (this.props.value) {
            url = `${this.props.url}${this.props.value}/`;
        }
        console.log(url);
        let post_type = this.state.archive_only ? 'POST' : 'DELETE';

        this.setState(
            {
                waiting_for_response: true,
            },
            function () {
                ajax_wrapper(post_type, url, {}, this.delete_callback);
            },
        );
    };

    delete_callback = (value) => {
        console.log(this.state.archive_only ? 'ARCHIVED' : 'DELETED');
        this.setState({
            show_confirm_modal: false,
        });

        if (this.props.callback) {
            this.props.callback(value);
        }
    };

    render() {
        let disabled = this.state.waiting_for_response;
        let delete_text = this.props.text ? this.props.text : 'Delete';
        let button_type = this.state.archive_only ? 'warning' : 'danger';

        let main_button = (
            <Button onClick={this.open} type={button_type} disabled={disabled}>
                {delete_text}
            </Button>
        );

        let content = (
            <div className={this.props.className} style={this.props.style}>
                {main_button}
            </div>
        );

        let modal = null;
        if (this.state.show_confirm_modal) {
            modal = (
                <Modal show={true} on_hide={this.close}>
                    <DeleteModalContent
                        delete_text={delete_text}
                        archive_only={this.state.archive_only}
                        close={this.close}
                        delete={this.delete}
                    />
                </Modal>
            );

            content = modal;
        }

        return content;
    }
}

export class ArchiveButton extends React.Component {
    render() {
        return (
            <DeleteButton
                {...this.props}
                delete_text={this.props.text}
                archive_only={true}
            />
        );
    }
}
