import React, { Component } from 'react';
import $ from 'jquery';
import { ajax_wrapper } from 'functions';
import { Modal, TextInput, Select, Form } from 'library';
import { TagButton } from 'components';
import { sort_objects } from 'functions';

export default class NewTaggerModal extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            setTimeout(
                function () {
                    $('.new-tagger-input input').focus();
                }.bind(this),
                100,
            );
        }
    }

    submit(state) {
        let url = `/api/create_tagger/`;
        let data = {
            name: state.name,
            user: state.user,
            owner: state.owner,
        };

        ajax_wrapper('POST', url, data, (value) => {
            this.props.on_hide();
            this.props.callback(value);
        });
    }

    render() {
        let user_options = [];
        for (let item of this.props.data.users) {
            user_options.push({ text: item.email, value: item.id });
        }

        return (
            <div key={new Date().getTime()}>
                <Modal show={this.props.show} on_hide={this.props.on_hide}>
                    <div className="simple-card-container">
                        <div className="simple-card">
                            <h2>Create New Tagger</h2>

                            <div className="form-narrow">
                                <Form
                                    defaults={{}}
                                    submit={this.submit}
                                    submit_text="Create"
                                >
                                    <TextInput
                                        name="name"
                                        label="Name"
                                        required={true}
                                    />
                                    <Select
                                        name="user"
                                        label="Assign to User"
                                        options={user_options}
                                        required={true}
                                    />
                                    <Select
                                        name="owner"
                                        label="Owner"
                                        options={user_options}
                                        required={true}
                                    />
                                    <br />
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
